import request from '@/utils/request'

export function listMallConsumbales (query) {
  return request({
    url: '/wms/mall_consumbales/',
    method: 'get',
    params: query
  })
}

export function createMallConsumbales (parameter) {
  return request({
    url: '/wms/mall_consumbales/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putMallConsumbales (parameter, id) {
  return request({
    url: '/wms/mall_consumbales/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function deleteMallConsumbales (id) {
  return request({
    url: '/wms/mall_consumbales/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

export function listMallGoodsConsumbales (query) {
  return request({
    url: '/wms/mall_goods_consumbales/',
    method: 'get',
    params: query
  })
}

export function createMallGoodsConsumbales (parameter) {
  return request({
    url: '/wms/mall_goods_consumbales/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function putMallGoodsConsumbales (parameter, id) {
  return request({
    url: '/wms/mall_goods_consumbales/' + id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}

export function deleteMallGoodsConsumbales (id) {
  return request({
    url: '/wms/mall_goods_consumbales/' + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
